import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Http from "../Common/Http";
import BasicSetup from "./Sidebar/BasicSetup";
import Workplace from "./Sidebar/Workplace";
import StaffManagement from "./Sidebar/StaffManagement";
import Payroll from "./Sidebar/Payroll";
import Accounts from "./Sidebar/Accounts";
import SupplyChain from "./Sidebar/SupplyChain";
import Production from "./Sidebar/Production";
import Assets from "./Sidebar/Assets";
import Sales from "./Sidebar/Sales";
import SecondarySales from "./Sidebar/SecondarySales";
import $ from "jquery";

const Sidebar = () => {
  const [active, setActive] = useState(window.location.pathname);
  const [receiveData, setReceiveData] = useState([]);
  const location = useLocation();

  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Initialize the Bootstrap collapse plugin for the newly added elements
    $(".show-sub + .nav-sub").slideDown();

    $(".with-sub").on("click", function (e) {
      e.preventDefault();
      $(this).next().slideToggle();
      $(this).toggleClass("show-sub");
    });
  }, [receiveData]);

  if (location.pathname === "/" || location.pathname === "/login") return null;

  return (
    <div>
      <div className="am-sideleft">
        <ul className="nav am-sideleft-tab">
          <li className="nav-item">
            <a href="#mainMenu" className="nav-link active">
              <i className="icon ion-ios-home-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#emailMenu" className="nav-link">
              <i className="icon ion-ios-email-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#chatMenu" className="nav-link">
              <i className="icon ion-ios-chatboxes-outline tx-24" />
            </a>
          </li>
          <li className="nav-item">
            <a href="#settingMenu" className="nav-link">
              <i className="icon ion-ios-gear-outline tx-24" />
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div id="mainMenu" className="tab-pane active">
            {/* {sidebarComponent} */}
            <ul className="nav am-sideleft-menu">
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={`nav-link ${
                    active === "/dashboard" ? "active" : ""
                  }`}
                  onClick={() => setActive("/dashboard")}
                >
                  <i className="fa fa-tachometer" aria-hidden="true"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              {/* Basic Setup */}
              {(receiveData === "admin" || receiveData === "hr") && (
                <BasicSetup />
              )}
              {/* WorkPlace */}
              {(receiveData === "admin" || receiveData === "hr") && (
                <Workplace />
              )}
              {(receiveData === "admin" ||
                receiveData === "hr" ||
                receiveData === "staff") && <StaffManagement />}

              {/* Payroll */}
              {(receiveData === "admin" || receiveData === "hr") && <Payroll />}

              {/* Accounts */}
              {(receiveData === "admin" || receiveData === "accounts") && (
                <Accounts />
              )}

              {/* Supply Chain */}
              {(receiveData === "admin" ||
                receiveData === "supply_chain" ||
                receiveData === "depot") && <SupplyChain role={receiveData} />}

              {(receiveData === "admin" ||
                receiveData === "supply_chain" ||
                receiveData === "production" ||
                receiveData === "depot") && <Production role={receiveData} />}

              {/* Assets */}
              {(receiveData === "admin" || receiveData === "supply_chain") && (
                <Assets />
              )}

              {/* Sales */}
              {(receiveData === "admin" ||
                receiveData === "depot" ||
                receiveData === "supply_chain") && <Sales role={receiveData} />}

              {/* Secondary Sales */}
              {receiveData === "admin" && <SecondarySales />}

              {/* Reports */}
              {receiveData != "hr" && (
                <li className="nav-item">
                  <Link
                    to="/reports"
                    className={`nav-link ${
                      active === "/reports" ? "active" : ""
                    }`}
                    onClick={() => setActive("/reports")}
                  >
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <span>Reports</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {/* #mainMenu */}
        </div>
        {/* tab-content */}
      </div>
      {/* am-sideleft */}
    </div>
  );
};

export default Sidebar;
