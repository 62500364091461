import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Assets = (props) => {
  const [active, setActive] = useState(window.location.pathname);
  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "asset-category" || active === "asset"
              ? "active show-sub"
              : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Assets</span>
        </Link>
        <ul className="nav-sub">
          <li className="nav-item">
            <Link
              to="/asset-category"
              className={`nav-link ${
                active === "asset-category" ? "active" : ""
              }`}
              onClick={() => setActive("asset-category")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Asset Category
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset"
              className={`nav-link ${active === "asset" ? "active" : ""}`}
              onClick={() => setActive("asset")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Asset
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/asset-direct-purchase"
              className={`nav-link ${
                active === "asset-direct-purchase" ? "active" : ""
              }`}
              onClick={() => setActive("asset-direct-purchase")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Direct Purchase
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset-circular-rfq"
              className={`nav-link ${
                active === "asset-circular-rfq" ? "active" : ""
              }`}
              onClick={() => setActive("asset-circular-rfq")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Asset Circular Rfq
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset-final-quotation"
              className={`nav-link ${
                active === "asset-final-quotation" ? "active" : ""
              }`}
              onClick={() => setActive("asset-final-quotation")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Final Quotation
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset-purchase-order"
              className={`nav-link ${
                active === "asset-purchase-order" ? "active" : ""
              }`}
              onClick={() => setActive("asset-purchase-order")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Purchase Order
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset-grn"
              className={`nav-link ${active === "asset-grn" ? "active" : ""}`}
              onClick={() => setActive("asset-grn")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Purchase Order Receive
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset-purchase-order-return"
              className={`nav-link ${
                active === "asset-purchase-order-return" ? "active" : ""
              }`}
              onClick={() => setActive("asset-purchase-order-return")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Purchase Order Return
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset-assign"
              className={`nav-link ${
                active === "asset-assign" ? "active" : ""
              }`}
              onClick={() => setActive("asset-assign")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Assign
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/asset-maintenence-claim"
              className={`nav-link ${
                active === "asset-maintenence-claim" ? "active" : ""
              }`}
              onClick={() => setActive("asset-maintenence-claim")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Maintenance Claim
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default Assets;
