import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import Swal from "sweetalert2";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [url, setUrl] = useState(Http.getUri());

  const fetchData = () => {
    Http.get("/candidate").then((res) => {
      setReceiveData(res.data);
    });

    const splitUrl = url.split("/");
    let fullUrl = "";
    for (var i = 0; i < splitUrl.length - 1; i++) {
      fullUrl += splitUrl[i] + "/";
    }
    setUrl(fullUrl);
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };
  const invite = (id, status) => {
    Http.get("/candidate_invite_change/" + status + "/" + id).then((res) => {
      setReceiveData(res.data);
      Swal.fire(
        "Candiate Invitation!",
        "Candidate Invitation Has Been Updated",
        "success"
      );
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Candidate</h5>
            <Link to="/create-candidate" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Candidate
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Name
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Email
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Mobile
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Experience
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Skills
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Expected Salary
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Expected Joining Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Reference
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          CV
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Status
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Remarks
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.name}</td>
                          <td className="text-xs">{item.email}</td>
                          <td className="text-xs">{item.mobile}</td>
                          <td className="text-xs">{item.experience}</td>
                          <td className="text-xs">{item.skills}</td>
                          <td className="text-xs">{item.expected_salary}</td>
                          <td className="text-xs">
                            {item.expected_joining_date}
                          </td>
                          <td className="text-xs">{item.reference_name}</td>
                          <td className="text-xs">
                            {item.cv ? (
                              <a href={url + "cv/" + item.cv} target="_blank">
                                CV Download
                              </a>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-xs">{item.status}</td>
                          <td className="text-xs">{item.remarks}</td>
                          <td className="text-xs">
                            {item.invite == 0 && item.approved_by == "" ? (
                              <button
                                className="btn btn-info btn-sm ms-1"
                                onClick={() => invite(item.id, 1)}
                              >
                                <i className="fa fa-check"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            {item.invite == 1 && item.approved_by == "" ? (
                              <button
                                className="btn btn-primary btn-sm ms-1"
                                onClick={() => invite(item.id, 0)}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            {item.approved_by < 1 ? (
                              <Link
                                to={{
                                  pathname: "/create-staff/" + item.id,
                                }}
                                className="btn btn-info btn-sm ms-1"
                              >
                                <i
                                  className="fa fa-list"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            ) : (
                              ""
                            )}
                            {item.approved_by < 1 ? (
                              <button
                                className="btn btn-danger btn-sm ms-1"
                                onClick={() =>
                                  deleteData(item.id, "candidate", "Candidate")
                                }
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            ) : (
                              "Approved"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
