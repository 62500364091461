import React, { useEffect, useState } from "react";
import IsLogin from "../Common/IsLogin";
import Http from "../Common/Http";
import StaffDashboard from "./Dashboard/Staff";
import AdminDashboard from "./Dashboard/Admin";
import { LoaderComponent } from "../Common/LoaderComponent";
import $ from "jquery";

const Dashboard = () => {
  const [receiveData, setReceiveData] = useState([]);

  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />

      {(receiveData === "admin" ||
        receiveData === "depot" ||
        receiveData === "supply_chain") && <AdminDashboard />}
      {(receiveData === "staff" ||
        receiveData === "hr" ||
        receiveData === "so") && <StaffDashboard />}
    </div>
  );
};

export default Dashboard;
