import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SupplyChain = ({ role }) => {
  const [active, setActive] = useState(window.location.pathname);

  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "vendors" ||
            active === "unit" ||
            active === "product-category" ||
            active === "product" ||
            active === "circular-rfq" ||
            active === "final-quotation" ||
            active === "purchase-order" ||
            active === "work-order" ||
            active === "grn" ||
            active === "others-receive" ||
            active === "purchase-order-return"
              ? "active show-sub"
              : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Supply Chain</span>
        </Link>
        <ul className="nav-sub">
          {role === "admin" ||
            (role === "supply_chain" && (
              <>
                <li className="nav-item">
                  <Link
                    to="/vendors"
                    className={`nav-link ${
                      active === "vendors" ? "active" : ""
                    }`}
                    onClick={() => setActive("vendors")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Vendors
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/distributors"
                    className={`nav-link ${
                      active === "distributors" ? "active" : ""
                    }`}
                    onClick={() => setActive("distributors")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Distributors
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/unit"
                    className={`nav-link ${active === "unit" ? "active" : ""}`}
                    onClick={() => setActive("unit")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Unit
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/product-category"
                    className={`nav-link ${
                      active === "product-category" ? "active" : ""
                    }`}
                    onClick={() => setActive("product-category")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Product Category
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/product"
                    className={`nav-link ${
                      active === "product" ? "active" : ""
                    }`}
                    onClick={() => setActive("product")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Product
                  </Link>
                </li>
              </>
            ))}
          <li className="nav-item">
            <Link
              to="/direct-purchase"
              className={`nav-link ${
                active === "direct-purchase" ? "active" : ""
              }`}
              onClick={() => setActive("direct-purchase")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Direct Purchase
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/circular-rfq"
              className={`nav-link ${
                active === "circular-rfq" ? "active" : ""
              }`}
              onClick={() => setActive("circular-rfq")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Circular RFQ
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/final-quotation"
              className={`nav-link ${
                active === "final-quotation" ? "active" : ""
              }`}
              onClick={() => setActive("final-quotation")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Final Quotation
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/purchase-order"
              className={`nav-link ${
                active === "purchase-order" ? "active" : ""
              }`}
              onClick={() => setActive("purchase-order")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Purchase Order
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/grn"
              className={`nav-link ${active === "grn" ? "active" : ""}`}
              onClick={() => setActive("grn")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; GRN
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/purchase-order-return"
              className={`nav-link ${
                active === "purchase-order-return" ? "active" : ""
              }`}
              onClick={() => setActive("purchase-order-return")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Purchase Order Return
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/others-receive"
              className={`nav-link ${
                active === "others-receive" ? "active" : ""
              }`}
              onClick={() => setActive("others-receive")}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Others Receive
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default SupplyChain;
