import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Header from "./Components/Layouts/Header";
import Sidebar from "./Components/Layouts/Sidebar";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./Components/Pages/Login";
import Dashboard from "./Components/Pages/Dashboard";
import Forms from "./Components/Pages/Forms"; // For Test Purpose. Should be deleted later on
import Testtable from "./Components/Pages/Testtable"; // For Test Purpose. Should be deleted later on
import Notification from "./Components/Pages/Notification/List";

import Designation from "./Components/Pages/Basic-Setup/Designation/List";
import CreateDesignation from "./Components/Pages/Basic-Setup/Designation/Create";
import EditDesignation from "./Components/Pages/Basic-Setup/Designation/Edit";

import Grade from "./Components/Pages/Basic-Setup/Grade/List";
import CreateGrade from "./Components/Pages/Basic-Setup/Grade/Create";
import EditGrade from "./Components/Pages/Basic-Setup/Grade/Edit";

import Department from "./Components/Pages/Basic-Setup/Department/List";
import CreateDepartment from "./Components/Pages/Basic-Setup/Department/Create";
import EditDepartment from "./Components/Pages/Basic-Setup/Department/Edit";

import CompanyLocation from "./Components/Pages/Basic-Setup/Location/List";
import CreateCompanyLocation from "./Components/Pages/Basic-Setup/Location/Create";
import EditCompanyLocation from "./Components/Pages/Basic-Setup/Location/Edit";

import Factory from "./Components/Pages/Basic-Setup/Factory/List";
import CreateFactory from "./Components/Pages/Basic-Setup/Factory/Create";
import EditFactory from "./Components/Pages/Basic-Setup/Factory/Edit";

import LeaveRules from "./Components/Pages/Basic-Setup/leave-rules/LeaveRules";
import CreateLeaveRules from "./Components/Pages/Basic-Setup/leave-rules/CreateLeaveRules";
import EditLeaveRules from "./Components/Pages/Basic-Setup/leave-rules/EditLeaveRules";

import Region from "./Components/Pages/Basic-Setup/Workplace/Region/List";
import CreateRegion from "./Components/Pages/Basic-Setup/Workplace/Region/Create";
import EditRegion from "./Components/Pages/Basic-Setup/Workplace/Region/Edit";

import Area from "./Components/Pages/Basic-Setup/Workplace/Area/List";
import CreateArea from "./Components/Pages/Basic-Setup/Workplace/Area/Create";
import EditArea from "./Components/Pages/Basic-Setup/Workplace/Area/Edit";

import Base from "./Components/Pages/Basic-Setup/Workplace/Base/List";
import CreateBase from "./Components/Pages/Basic-Setup/Workplace/Base/Create";
import EditBase from "./Components/Pages/Basic-Setup/Workplace/Base/Edit";

import WorkplaceRoute from "./Components/Pages/Basic-Setup/Workplace/Route/List";
import CreateRoute from "./Components/Pages/Basic-Setup/Workplace/Route/Create";
import EditRoute from "./Components/Pages/Basic-Setup/Workplace/Route/Edit";

import Staff from "./Components/Pages/HrManagement/Staff/Staff";
import CreateStaff from "./Components/Pages/HrManagement/Staff/CreateStaff";
import EditStaff from "./Components/Pages/HrManagement/Staff/EditStaff";
import Appointment from "./Components/Pages/HrManagement/Staff/Appointment";
import IdCard from "./Components/Pages/HrManagement/Staff/IdCard";
import ProfileStaff from "./Components/Pages/HrManagement/Staff/Profile/Profile";
import ProfileEdit from "./Components/Pages/HrManagement/Staff/Profile/Edit";

// Payroll
import BillSubmission from "./Components/Pages/HrManagement/Bill-Submission/List";
import CreateBillSubmission from "./Components/Pages/HrManagement/Bill-Submission/Create";
import EditBillSubmission from "./Components/Pages/HrManagement/Bill-Submission/Edit";
import BillSubmissionView from "./Components/Pages/HrManagement/Bill-Submission/View";

import MovementSlip from "./Components/Pages/HrManagement/Movement-Slip/List";
import CreateMovementSlip from "./Components/Pages/HrManagement/Movement-Slip/Create";
import EditMovementSlip from "./Components/Pages/HrManagement/Movement-Slip/Edit";
import MovementSlipView from "./Components/Pages/HrManagement/Movement-Slip/View";

import Attendance from "./Components/Pages/HrManagement/Attendance/List";
import CreateAttendance from "./Components/Pages/HrManagement/Attendance/Create";
import EditAttendance from "./Components/Pages/HrManagement/Attendance/Edit";
import ImportAttendance from "./Components/Pages/HrManagement/Attendance/Import";

import Arrear from "./Components/Pages/HrManagement/Arrear/List";
import CreateArrear from "./Components/Pages/HrManagement/Arrear/Create";
import EditArrear from "./Components/Pages/HrManagement/Arrear/Edit";

import Incentive from "./Components/Pages/HrManagement/Incentive/List";
import CreateIncentive from "./Components/Pages/HrManagement/Incentive/Create";
import EditIncentive from "./Components/Pages/HrManagement/Incentive/Edit";

import Overtime from "./Components/Pages/HrManagement/Overtime/List";
import CreateOvertime from "./Components/Pages/HrManagement/Overtime/Create";
import EditOvertime from "./Components/Pages/HrManagement/Overtime/Edit";

import Holiday from "./Components/Pages/HrManagement/Holiday/List";
import CreateHoliday from "./Components/Pages/HrManagement/Holiday/Create";
import EditHoliday from "./Components/Pages/HrManagement/Holiday/Edit";

import HolidayList from "./Components/Pages/HrManagement/HolidayList/List";
import CreateHolidayList from "./Components/Pages/HrManagement/HolidayList/Create";
import EditHolidayList from "./Components/Pages/HrManagement/HolidayList/Edit";

import RoasterHolidayList from "./Components/Pages/HrManagement/RoasterHolidayList/List";
import CreateRoasterHolidayList from "./Components/Pages/HrManagement/RoasterHolidayList/Create";
import EditRoasterHolidayList from "./Components/Pages/HrManagement/RoasterHolidayList/Edit";

import Settings from "./Components/Pages/Basic-Setup/settings/Edit";

import WeeklyOffDay from "./Components/Pages/Basic-Setup/WeeklyOffDay/List";
import CreateWeeklyOffDay from "./Components/Pages/Basic-Setup/WeeklyOffDay/Create";
import EditWeeklyOffDay from "./Components/Pages/Basic-Setup/WeeklyOffDay/Edit";

import NightHold from "./Components/Pages/HrManagement/NightHold/List";
import CreateNightHold from "./Components/Pages/HrManagement/NightHold/Create";
import EditNightHold from "./Components/Pages/HrManagement/NightHold/Edit";

import Tada from "./Components/Pages/HrManagement/Tada/List";
import CreateTada from "./Components/Pages/HrManagement/Tada/Create";
import EditTada from "./Components/Pages/HrManagement/Tada/Edit";

import SpecialLateFacility from "./Components/Pages/Basic-Setup/SpecialLateFacility/List";
import CreateSpecialLateFacility from "./Components/Pages/Basic-Setup/SpecialLateFacility/Create";
import EditSpecialLateFacility from "./Components/Pages/Basic-Setup/SpecialLateFacility/Edit";

import SalaryAdvance from "./Components/Pages/HrManagement/SalaryAdvance/List";
import CreateSalaryAdvance from "./Components/Pages/HrManagement/SalaryAdvance/Create";
import EditSalaryAdvance from "./Components/Pages/HrManagement/SalaryAdvance/Edit";

import Canteen from "./Components/Pages/HrManagement/Canteen/List";
import CreateCanteen from "./Components/Pages/HrManagement/Canteen/Create";
import EditCanteen from "./Components/Pages/HrManagement/Canteen/Edit";

import PhoneBill from "./Components/Pages/HrManagement/PhoneBill/List";
import CreatePhoneBill from "./Components/Pages/HrManagement/PhoneBill/Create";
import EditPhoneBill from "./Components/Pages/HrManagement/PhoneBill/Edit";

import IncomeTax from "./Components/Pages/HrManagement/IncomeTax/List";
import CreateIncomeTax from "./Components/Pages/HrManagement/IncomeTax/Create";
import EditIncomeTax from "./Components/Pages/HrManagement/IncomeTax/Edit";

import Deduction from "./Components/Pages/HrManagement/Deduction/List";
import CreateDeduction from "./Components/Pages/HrManagement/Deduction/Create";
import EditDeduction from "./Components/Pages/HrManagement/Deduction/Edit";

import Bonous from "./Components/Pages/HrManagement/Bonous/List";
import CreateBonous from "./Components/Pages/HrManagement/Bonous/Create";
import EditBonous from "./Components/Pages/HrManagement/Bonous/Edit";

import LeaveDeduction from "./Components/Pages/HrManagement/LeaveDeduction/List";
import CreateLeaveDeduction from "./Components/Pages/HrManagement/LeaveDeduction/Create";
import EditLeaveDeduction from "./Components/Pages/HrManagement/LeaveDeduction/Edit";

import LeaveApplication from "./Components/Pages/HrManagement/LeaveApplication/List";
import CreateLeaveApplication from "./Components/Pages/HrManagement/LeaveApplication/Create";
import EditLeaveApplication from "./Components/Pages/HrManagement/LeaveApplication/Edit";
import LeaveApplicationView from "./Components/Pages/HrManagement/LeaveApplication/View";

import Requisition from "./Components/Pages/HrManagement/Requisition/List";
import CreateRequisition from "./Components/Pages/HrManagement/Requisition/Create";
import EditRequisition from "./Components/Pages/HrManagement/Requisition/Edit";

import Candidate from "./Components/Pages/HrManagement/Candidate/List";
import CreateCandidate from "./Components/Pages/HrManagement/Candidate/Create";
import EditCandidate from "./Components/Pages/HrManagement/Candidate/Edit";

import Salary from "./Components/Pages/HrManagement/Salary/List";
import CreateSalary from "./Components/Pages/HrManagement/Salary/Create";
import PaySlip from "./Components/Pages/HrManagement/Salary/PaySlip";

// Accounts
import BudgetPlan from "./Components/Pages/Budget/Plan/List";
import CreateBudgetPlan from "./Components/Pages/Budget/Plan/Create";
import EditBudgetPlan from "./Components/Pages/Budget/Plan/Edit";
import ShowBudgetPlan from "./Components/Pages/Budget/Plan/Show";

import TreeChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/Tree";
import ChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/List";
import CreateChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/Create";
import EditChartOfAccount from "./Components/Pages/Accounts/Chart-Of-Account/Edit";

import JournalCode from "./Components/Pages/Accounts/Journal-Code/List";
import CreateJournalCode from "./Components/Pages/Accounts/Journal-Code/Create";
import EditJournalCode from "./Components/Pages/Accounts/Journal-Code/Edit";

import SubCategory from "./Components/Pages/Accounts/Sub-Category/List";
import CreateSubCategory from "./Components/Pages/Accounts/Sub-Category/Create";
import EditSubCategory from "./Components/Pages/Accounts/Sub-Category/Edit";

import JournalVoucher from "./Components/Pages/Accounts/Journal-Voucher/List";
import CreateJournalVoucher from "./Components/Pages/Accounts/Journal-Voucher/Create";
import EditJournalVoucher from "./Components/Pages/Accounts/Journal-Voucher/Edit";

import CashCount from "./Components/Pages/Accounts/Cash-Count/List";
import CreateCashCount from "./Components/Pages/Accounts/Cash-Count/Create";
import EditCashCount from "./Components/Pages/Accounts/Cash-Count/Edit";

import AutoJournalSetup from "./Components/Pages/Accounts/Setup/List";
import CreateSetup from "./Components/Pages/Accounts/Setup/Create";

import TransactionsListConnect from "./Components/Pages/Accounts/Transactions-List-Connect/List";
import EditTransactionsListConnect from "./Components/Pages/Accounts/Transactions-List-Connect/Edit";

import Apa from "./Components/Pages/HrManagement/Apa/List";
import CreateApa from "./Components/Pages/HrManagement/Apa/Create";
import EditApa from "./Components/Pages/HrManagement/Apa/Edit";

// Supply Chain
import Unit from "./Components/Pages/SupplyChain/Unit/List";
import CreateUnit from "./Components/Pages/SupplyChain/Unit/Create";
import EditUnit from "./Components/Pages/SupplyChain/Unit/Edit";

import Vendors from "./Components/Pages/SupplyChain/Vendor/List";
import CreateVendor from "./Components/Pages/SupplyChain/Vendor/Create";
import EditVendor from "./Components/Pages/SupplyChain/Vendor/Edit";
import ViewVendor from "./Components/Pages/SupplyChain/Vendor/View";

import ProductCategory from "./Components/Pages/SupplyChain/Product-Category/List";
import CreateProductCategory from "./Components/Pages/SupplyChain/Product-Category/Create";
import EditProductCategory from "./Components/Pages/SupplyChain/Product-Category/Edit";

import Product from "./Components/Pages/SupplyChain/Product/List";
import CreateProduct from "./Components/Pages/SupplyChain/Product/Create";
import EditProduct from "./Components/Pages/SupplyChain/Product/Edit";

import DirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/List";
import CreateDirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/Create";
import EditDirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/Edit";
import ShowDirectPurchase from "./Components/Pages/SupplyChain/Direct-Purchase/Show";

import CircularRfq from "./Components/Pages/SupplyChain/CircularRfq/List";
import CreateCircularRfq from "./Components/Pages/SupplyChain/CircularRfq/Create";
import EditCircularRfq from "./Components/Pages/SupplyChain/CircularRfq/Edit";
import VendorQuotationSent from "./Components/Pages/SupplyChain/CircularRfq/Vendor/Quotation";
import ShowCircularRfq from "./Components/Pages/SupplyChain/CircularRfq/Show";

import VendorQuotationList from "./Components/Pages/SupplyChain/CircularRfq/Vendor/QuotationList";
import ShowVendorQuotation from "./Components/Pages/SupplyChain/CircularRfq/Vendor/Show";
import CircularCompare from "./Components/Pages/SupplyChain/CircularRfq/Vendor/Compare";

import FinalQuotation from "./Components/Pages/SupplyChain/Final-Quotation/List";
import FinalQuotationView from "./Components/Pages/SupplyChain/Final-Quotation/Show";

import PurchaseOrder from "./Components/Pages/SupplyChain/Purchase-Order/List";
import PurchaseOrderView from "./Components/Pages/SupplyChain/Purchase-Order/Show";
import CreateGrn from "./Components/Pages/SupplyChain/Grn/Create";

import Grn from "./Components/Pages/SupplyChain/Grn/List";
import GrnView from "./Components/Pages/SupplyChain/Grn/Show";
import GrnEdit from "./Components/Pages/SupplyChain/Grn/Edit";

import PurchaseOrderReturn from "./Components/Pages/SupplyChain/Grn-Return/List";
import PurchaseOrderReturnView from "./Components/Pages/SupplyChain/Grn-Return/Show";
import PurchaseOrderReturnCreate from "./Components/Pages/SupplyChain/Grn-Return/Create";
import PurchaseOrderReturnEdit from "./Components/Pages/SupplyChain/Grn-Return/Edit";

import OthersReceive from "./Components/Pages/SupplyChain/Others-Receive/List";
import OthersReceiveView from "./Components/Pages/SupplyChain/Others-Receive/Show";
import CreateothersReceive from "./Components/Pages/SupplyChain/Others-Receive/Create";
import EditothersReceive from "./Components/Pages/SupplyChain/Others-Receive/Edit";

// Assets
import AssetCategory from "./Components/Pages/Assets/Asset-Category/List";
import CreateAssetCategory from "./Components/Pages/Assets/Asset-Category/Create";
import EditAssetCategory from "./Components/Pages/Assets/Asset-Category/Edit";

import Asset from "./Components/Pages/Assets/Asset/List";
import CreateAsset from "./Components/Pages/Assets/Asset/Create";
import EditAsset from "./Components/Pages/Assets/Asset/Edit";

import AssetDirectPurchase from "./Components/Pages/Assets/Direct-Purchase/List";
import CreateAssetDirectPurchase from "./Components/Pages/Assets/Direct-Purchase/Create";
import EditAssetDirectPurchase from "./Components/Pages/Assets/Direct-Purchase/Edit";

import AssetMaintenenceClaim from "./Components/Pages/Assets/Maintenance-Claim/List";
import CreateAssetMaintenenceClaim from "./Components/Pages/Assets/Maintenance-Claim/Create";
import EditAssetMaintenenceClaim from "./Components/Pages/Assets/Maintenance-Claim/Edit";

import AssetDamageClaim from "./Components/Pages/Assets/Damage-Claim/List";
import CreateAssetDamageClaim from "./Components/Pages/Assets/Damage-Claim/Create";
import EditAssetDamageClaim from "./Components/Pages/Assets/Damage-Claim/Edit";

import AssetAssign from "./Components/Pages/Assets/Assign/List";
import CreateAssetAssign from "./Components/Pages/Assets/Assign/Create";
import EditAssetAssign from "./Components/Pages/Assets/Assign/Edit";
import ReturnAssetAssign from "./Components/Pages/Assets/Assign/Return";
import DamageAssetClaim from "./Components/Pages/Assets/Assign/Damage";

import AssetCircularRfq from "./Components/Pages/Assets/Asset-CircularRfq/List";
import CreateAssetCircularRfq from "./Components/Pages/Assets/Asset-CircularRfq/Create";
import EditAssetCircularRfq from "./Components/Pages/Assets/Asset-CircularRfq/Edit";

import VendorAssetQuotationSent from "./Components/Pages/Assets/Asset-CircularRfq/Vendor/Quotation";
import VendorAssetQuotationList from "./Components/Pages/Assets/Asset-CircularRfq/Vendor/QuotationList";
import AssetCircularCompare from "./Components/Pages/Assets/Asset-CircularRfq/Vendor/Compare";

import AssetFinalQuotation from "./Components/Pages/Assets/Final-Quotation/List";
import AssetFinalQuotationView from "./Components/Pages/Assets/Final-Quotation/Show";

import AssetPurchaseOrder from "./Components/Pages/Assets/Purchase-Order/List";
import AssetPurchaseOrderView from "./Components/Pages/Assets/Purchase-Order/Show";
import AssetCreateGrn from "./Components/Pages/Assets/Grn/Create";

import AssetGrn from "./Components/Pages/Assets/Grn/List";
import AssetGrnView from "./Components/Pages/Assets/Grn/Show";
import AssetGrnEdit from "./Components/Pages/Assets/Grn/Edit";

import AssetPurchaseOrderReturn from "./Components/Pages/Assets/Grn-Return/List";
import AssetPurchaseOrderReturnView from "./Components/Pages/Assets/Grn-Return/Show";
import AssetPurchaseOrderReturnCreate from "./Components/Pages/Assets/Grn-Return/Create";
import AssetPurchaseOrderReturnEdit from "./Components/Pages/Assets/Grn-Return/Edit";

// Production
import Machineries from "./Components/Pages/Production/Machineries/List";
import CreateMachineries from "./Components/Pages/Production/Machineries/Create";
import EditMachineries from "./Components/Pages/Production/Machineries/Edit";

import FactoryMachineries from "./Components/Pages/Production/Factory-Machineries/List";
import CreateFactoryMachineries from "./Components/Pages/Production/Factory-Machineries/Create";
import EditFactoryMachineries from "./Components/Pages/Production/Factory-Machineries/Edit";

import Receipe from "./Components/Pages/Production/Receipe/List";
import CreateReceipe from "./Components/Pages/Production/Receipe/Create";
import EditReceipe from "./Components/Pages/Production/Receipe/Edit";

import ReceipeFactory from "./Components/Pages/Production/ReceipeFactory/List";
import CreateReceipeFactory from "./Components/Pages/Production/ReceipeFactory/Create";
import EditReceipeFactory from "./Components/Pages/Production/ReceipeFactory/Edit";

import RequisitionProduction from "./Components/Pages/Production/RequisitionProduction/List";
import CreateRequisitionProduction from "./Components/Pages/Production/RequisitionProduction/Create";
import EditRequisitionProduction from "./Components/Pages/Production/RequisitionProduction/Edit";
import ViewRequisitionProduction from "./Components/Pages/Production/RequisitionProduction/Show";

import Issue from "./Components/Pages/Production/Issue/List";
import CreateIssue from "./Components/Pages/Production/Issue/Create";
import EditIssue from "./Components/Pages/Production/Issue/Edit";
import ViewIssue from "./Components/Pages/Production/Issue/Show";
import RmPmReceive from "./Components/Pages/Production/Issue/Receive/Rmpm";
import ProductionGenerate from "./Components/Pages/Production/Issue/Receive/ProductionGenerate";
import ProductionReceive from "./Components/Pages/Production/Issue/Receive/Production";

import Production from "./Components/Pages/Production/Production/List";

import DepoTransfer from "./Components/Pages/Production/Depo-Transfer/List";

import FactoryTransfer from "./Components/Pages/Production/Factory-Transfer/List";
import CreateFactoryTransfer from "./Components/Pages/Production/Factory-Transfer/Create";
import EditFactoryTransfer from "./Components/Pages/Production/Factory-Transfer/Edit";
import FactoryTransferShow from "./Components/Pages/Production/Factory-Transfer/Show";

import DamageReturn from "./Components/Pages/Production/Damage-Return/List";
import CreateDamageReturn from "./Components/Pages/Production/Damage-Return/Create";
import EditDamageReturn from "./Components/Pages/Production/Damage-Return/Edit";

// Sales
import PrimarySales from "./Components/Pages/Sales/Primary-Sales/List";
import CreatePrimarySales from "./Components/Pages/Sales/Primary-Sales/Create";
import EditPrimarySales from "./Components/Pages/Sales/Primary-Sales/Edit";
import PaymentReceive from "./Components/Pages/Sales/Primary-Sales/PaymentReceive";
import PaymentReceiveCoa from "./Components/Pages/Sales/Primary-Sales/PaymentReceiveCoa";
import OrderDetail from "./Components/Pages/Sales/Primary-Sales/OrderDetail";
import DeliveryOrder from "./Components/Pages/Sales/Primary-Sales/DeliveryOrder";

import Mushok63 from "./Components/Pages/Sales/Primary-Sales/Mushok63";
import Chalan from "./Components/Pages/Sales/Primary-Sales/Chalan";

import Distributors from "./Components/Pages/Sales/Distributors/List";
import CreateDistributors from "./Components/Pages/Sales/Distributors/Create";
import EditDistributors from "./Components/Pages/Sales/Distributors/Edit";
import DistributorsView from "./Components/Pages/Sales/Distributors/View";

// Secondary Sales
import MonthlyTarget from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/List";
import MonthlyTargetView from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/View";
import MonthlyTargetDailyReport from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/DailyReport";
import SummaryReport from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/SummaryReport";
import MonthlyTargetViewRegionWise from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/ViewRegionWise";
import MonthlyTargetViewBaseWiseDaily from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/ViewBaseWiseDaily";
import CreateMonthlyTarget from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/CreateTarget";
import EditMonthlyTarget from "./Components/Pages/Sales/Secondary-Sales/Monthly-Target/EditTarget";

import OpeningStock from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/List";
import OpeningStockView from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/View";
import CreateOpeningStock from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/Create";
import EditOpeningStock from "./Components/Pages/Sales/Secondary-Sales/Opening-Stock/Edit";

// Reports
import Reports from "./Components/Pages/Reports/List";
import HrSalaryStructure from "./Components/Pages/Reports/HrManagement/SalaryStructure";
import HrLeaveReport from "./Components/Pages/Reports/HrManagement/LeaveReport";
import MonthlySalaryReport from "./Components/Pages/Reports/HrManagement/MonthlySalaryReport";
import AttendanceReport from "./Components/Pages/Reports/HrManagement/AttendanceReport";
import SalaryPaymentReport from "./Components/Pages/Reports/HrManagement/SalaryPaymentReport";
import ApaReport from "./Components/Pages/Reports/HrManagement/ApaReport";
import ChartOfAccountLedgerReport from "./Components/Pages/Reports/Accounts/ChartOfAccountLedgerReport";
import SubcategoryListReport from "./Components/Pages/Reports/Accounts/SubcategoryListReport";
import SubcategoryLedgerReport from "./Components/Pages/Reports/Accounts/SubcategoryLedgerReport";
import VendorSupplierPayableReport from "./Components/Pages/Reports/Accounts/VendorSupplierPayableReport";
import DistributorPayableReport from "./Components/Pages/Reports/Accounts/DistributorPayableReport";
import ReceiptPaymentSummaryReport from "./Components/Pages/Reports/Accounts/ReceiptPaymentSummaryReport";
import VendorSupplierReceivableReport from "./Components/Pages/Reports/Accounts/VendorSupplierReceivableReport";
import DistributorReceivableReport from "./Components/Pages/Reports/Accounts/DistributorReceivableReport";
import TrialBalance from "./Components/Pages/Reports/Accounts/TrialBalance";
import DepoWiseProductStock from "./Components/Pages/Reports/Depot/DepoWiseProductStock";
import RawMaterialStock from "./Components/Pages/Reports/Depot/RawMaterialStock";
import PackingMaterialStock from "./Components/Pages/Reports/Depot/PackingMaterialStock";
import ProductStock from "./Components/Pages/Reports/Depot/ProductStock";
import Distributor from "./Components/Pages/Reports/Depot/Distributor";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Header />
    <Sidebar />

    <div id="erp-content">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/form" element={<Forms />} />
        <Route path="/testtable" element={<Testtable />} />
        <Route path="/notification" element={<Notification />} />
        {/* Designation */}
        <Route path="/designation" element={<Designation />} />
        <Route path="/createdesignation" element={<CreateDesignation />} />
        <Route path="/editdesignation/:id" element={<EditDesignation />} />
        {/* Grade */}
        <Route path="/grade" element={<Grade />} />
        <Route path="/creategrade" element={<CreateGrade />} />
        <Route path="/editgrade/:id" element={<EditGrade />} />
        {/* Department */}
        <Route path="/department" element={<Department />} />
        <Route path="/createdepartment" element={<CreateDepartment />} />
        <Route path="/editdepartment/:id" element={<EditDepartment />} />
        {/* Company Location */}
        <Route path="/companylocation" element={<CompanyLocation />} />
        <Route
          path="/createCompanyLocation"
          element={<CreateCompanyLocation />}
        />
        <Route
          path="/editCompanyLocation/:id"
          element={<EditCompanyLocation />}
        />

        <Route path="/factory" element={<Factory />} />
        <Route path="/factory/create" element={<CreateFactory />} />
        <Route path="/factory/edit/:id" element={<EditFactory />} />
        {/* Company Location */}
        {/* Region */}
        <Route path="/region" element={<Region />} />
        <Route path="/createregion" element={<CreateRegion />} />
        <Route path="/editregion/:id" element={<EditRegion />} />
        {/* Area */}
        <Route path="/area" element={<Area />} />
        <Route path="/createarea" element={<CreateArea />} />
        <Route path="/editarea/:id" element={<EditArea />} />
        {/* Base */}
        <Route path="/base" element={<Base />} />
        <Route path="/createbase" element={<CreateBase />} />
        <Route path="/editbase/:id" element={<EditBase />} />
        {/* Route */}
        <Route path="/route" element={<WorkplaceRoute />} />
        <Route path="/createroute" element={<CreateRoute />} />
        <Route path="/editroute/:id" element={<EditRoute />} />
        {/* Leave Rules */}
        <Route path="/leave-rules" element={<LeaveRules />} />
        <Route path="/create-leave-rules" element={<CreateLeaveRules />} />
        <Route path="/edit-leave-rules/:id" element={<EditLeaveRules />} />
        {/* Staff */}
        <Route path="/staff" element={<Staff />} />
        <Route path="/create-staff" element={<CreateStaff />} />
        <Route path="/create-staff/:id" element={<CreateStaff />} />
        <Route path="/edit-staff/:id" element={<EditStaff />} />
        <Route path="/appointment-letter/:id" element={<Appointment />} />
        <Route path="/idcard/:id" element={<IdCard />} />
        <Route path="/profile/:id" element={<ProfileStaff />} />
        <Route path="/profile-edit" element={<ProfileEdit />} />
        {/* Payroll */}
        {/* Bill Submission */}
        <Route path="/billsubmission" element={<BillSubmission />} />
        <Route
          path="/create-bill-submission"
          element={<CreateBillSubmission />}
        />
        <Route
          path="/edit-bill-submission/:id"
          element={<EditBillSubmission />}
        />
        <Route
          path="/bill-submission-view/:id"
          element={<BillSubmissionView />}
        />
        {/* Movement Slip */}
        <Route path="/movement-slip" element={<MovementSlip />} />
        <Route path="/create-movement-slip" element={<CreateMovementSlip />} />
        <Route path="/edit-movement-slip/:id" element={<EditMovementSlip />} />
        <Route path="/movement-slip-view/:id" element={<MovementSlipView />} />
        {/* Attendance */}
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/create-attendance" element={<CreateAttendance />} />
        <Route path="/edit-attendance/:id" element={<EditAttendance />} />
        <Route path="/import-attendance" element={<ImportAttendance />} />
        {/* Arrear */}
        <Route path="/arrear" element={<Arrear />} />
        <Route path="/create-arrear" element={<CreateArrear />} />
        <Route path="/edit-arrear/:id" element={<EditArrear />} />
        {/* Incentive */}
        <Route path="/incentive" element={<Incentive />} />
        <Route path="/create-incentive" element={<CreateIncentive />} />
        <Route path="/edit-incentive/:id" element={<EditIncentive />} />
        {/* Overtime */}
        <Route path="/overtime" element={<Overtime />} />
        <Route path="/create-overtime" element={<CreateOvertime />} />
        <Route path="/edit-overtime/:id" element={<EditOvertime />} />
        {/* Holiday */}
        <Route path="/holiday" element={<Holiday />} />
        <Route path="/create-holiday" element={<CreateHoliday />} />
        <Route path="/edit-holiday/:id" element={<EditHoliday />} />
        {/* HolidayList */}
        <Route path="/holiday-list" element={<HolidayList />} />
        <Route path="/create-holiday-list" element={<CreateHolidayList />} />
        <Route path="/edit-holiday-list/:id" element={<EditHolidayList />} />
        {/* Roaster HolidayList */}
        <Route path="/roaster-holiday-list" element={<RoasterHolidayList />} />
        <Route
          path="/create-roaster-holiday-list"
          element={<CreateRoasterHolidayList />}
        />
        <Route
          path="/edit-roaster-holiday-list/:id"
          element={<EditRoasterHolidayList />}
        />
        {/* WeeklyOffDay */}
        <Route path="/weekly-off-day" element={<WeeklyOffDay />} />
        <Route path="/create-weekly-off-day" element={<CreateWeeklyOffDay />} />
        <Route path="/edit-weekly-off-day/:id" element={<EditWeeklyOffDay />} />
        <Route path="/setting" element={<Settings />} />
        <Route
          path="/special-late-facility"
          element={<SpecialLateFacility />}
        />
        <Route
          path="/create-special-late-facility"
          element={<CreateSpecialLateFacility />}
        />
        <Route
          path="/edit-special-late-facility/:id"
          element={<EditSpecialLateFacility />}
        />
        {/* NightHold */}
        <Route path="/night-hold" element={<NightHold />} />
        <Route path="/create-night-hold" element={<CreateNightHold />} />
        <Route path="/edit-night-hold/:id" element={<EditNightHold />} />
        {/* tada */}
        <Route path="/tada" element={<Tada />} />
        <Route path="/create-tada" element={<CreateTada />} />
        <Route path="/edit-tada/:id" element={<EditTada />} />
        {/* SalaryAdvance */}
        <Route path="/salary-advance" element={<SalaryAdvance />} />
        <Route
          path="/create-salary-advance"
          element={<CreateSalaryAdvance />}
        />
        <Route
          path="/edit-salary-advance/:id"
          element={<EditSalaryAdvance />}
        />
        {/* Canteen */}
        <Route path="/canteen" element={<Canteen />} />
        <Route path="/create-canteen" element={<CreateCanteen />} />
        <Route path="/edit-canteen/:id" element={<EditCanteen />} />
        {/* PhoneBill */}
        <Route path="/phonebill" element={<PhoneBill />} />
        <Route path="/create-phonebill" element={<CreatePhoneBill />} />
        <Route path="/edit-phonebill/:id" element={<EditPhoneBill />} />
        {/* IncomeTax */}
        <Route path="/incometax" element={<IncomeTax />} />
        <Route path="/create-incometax" element={<CreateIncomeTax />} />
        <Route path="/edit-incometax/:id" element={<EditIncomeTax />} />
        {/* Deduction */}
        <Route path="/deduction" element={<Deduction />} />
        <Route path="/create-deduction" element={<CreateDeduction />} />
        <Route path="/edit-deduction/:id" element={<EditDeduction />} />
        {/* Bonous */}
        <Route path="/bonous" element={<Bonous />} />
        <Route path="/create-bonous" element={<CreateBonous />} />
        <Route path="/edit-bonous/:id" element={<EditBonous />} />
        {/* Apa */}
        <Route path="/Apa" element={<Apa />} />
        <Route path="/create-Apa" element={<CreateApa />} />
        <Route path="/edit-Apa/:id" element={<EditApa />} />
        {/* LeaveApplication */}
        <Route path="/leave-application" element={<LeaveApplication />} />
        <Route
          path="/create-leave-application"
          element={<CreateLeaveApplication />}
        />
        <Route
          path="/edit-leave-application/:id"
          element={<EditLeaveApplication />}
        />

        <Route
          path="/leave-application-view/:id"
          element={<LeaveApplicationView />}
        />
        {/* LeaveDeduction */}
        <Route path="/leave-deduction" element={<LeaveDeduction />} />
        <Route
          path="/create-leave-deduction"
          element={<CreateLeaveDeduction />}
        />
        <Route
          path="/edit-leave-deduction/:id"
          element={<EditLeaveDeduction />}
        />
        {/* Requisition */}
        <Route path="/requisition" element={<Requisition />} />
        <Route path="/create-requisition" element={<CreateRequisition />} />
        <Route path="/edit-requisition/:id" element={<EditRequisition />} />
        {/* Candidate */}
        <Route path="/candidate" element={<Candidate />} />
        <Route path="/create-candidate" element={<CreateCandidate />} />
        <Route path="/edit-candidate/:id" element={<EditCandidate />} />
        {/* Salary */}
        <Route path="/salary" element={<Salary />} />
        <Route path="/create-salary" element={<CreateSalary />} />
        <Route path="/pay-slip/:id" element={<PaySlip />} />
        {/* Accounts */}
        <Route path="/budget-plan" element={<BudgetPlan />} />
        <Route path="/create-budget-plan" element={<CreateBudgetPlan />} />
        <Route path="/edit-budget-plan/:id" element={<EditBudgetPlan />} />
        <Route path="/show-budget-plan/:id" element={<ShowBudgetPlan />} />

        <Route path="/tree-chart-of-account" element={<TreeChartOfAccount />} />
        <Route path="/chart-of-account" element={<ChartOfAccount />} />
        <Route
          path="/create-chart-of-account"
          element={<CreateChartOfAccount />}
        />
        <Route
          path="/edit-chart-of-account/:id"
          element={<EditChartOfAccount />}
        />

        <Route path="/journal-code" element={<JournalCode />} />
        <Route path="/create-journal-code" element={<CreateJournalCode />} />
        <Route path="/edit-journal-code/:id" element={<EditJournalCode />} />
        <Route path="/sub-category" element={<SubCategory />} />
        <Route path="/create-sub-category" element={<CreateSubCategory />} />
        <Route path="/edit-sub-category/:id" element={<EditSubCategory />} />
        <Route path="/journal-voucher" element={<JournalVoucher />} />
        <Route
          path="/create-journal-voucher"
          element={<CreateJournalVoucher />}
        />
        <Route
          path="/edit-journal-voucher/:id"
          element={<EditJournalVoucher />}
        />
        <Route path="/cash-count" element={<CashCount />} />
        <Route path="/create-cash-count" element={<CreateCashCount />} />
        <Route path="/edit-cash-count/:id" element={<EditCashCount />} />
        <Route path="/auto-journal-setup" element={<AutoJournalSetup />} />
        <Route path="/create-setup" element={<CreateSetup />} />

        <Route
          path="/transactions-list-connect"
          element={<TransactionsListConnect />}
        />
        <Route
          path="/edit-transactions-list-connect/:id"
          element={<EditTransactionsListConnect />}
        />

        {/* Supply Chain */}
        <Route path="/vendors" element={<Vendors />} />
        <Route path="/create-vendor" element={<CreateVendor />} />
        <Route path="/edit-vendor/:id" element={<EditVendor />} />
        <Route path="/vendor-details/:id" element={<ViewVendor />} />

        <Route path="/unit" element={<Unit />} />
        <Route path="/create-unit" element={<CreateUnit />} />
        <Route path="/edit-unit/:id" element={<EditUnit />} />

        <Route path="/product-category" element={<ProductCategory />} />
        <Route
          path="/create-product-category"
          element={<CreateProductCategory />}
        />
        <Route
          path="/edit-product-category/:id"
          element={<EditProductCategory />}
        />

        <Route path="/product" element={<Product />} />
        <Route path="/create-product" element={<CreateProduct />} />
        <Route path="/edit-product/:id" element={<EditProduct />} />

        <Route path="/direct-purchase" element={<DirectPurchase />} />
        <Route
          path="/create-direct-purchase"
          element={<CreateDirectPurchase />}
        />
        <Route
          path="/edit-direct-purchase/:id"
          element={<EditDirectPurchase />}
        />
        <Route
          path="/show-direct-purchase/:id"
          element={<ShowDirectPurchase />}
        />

        <Route path="/circular-rfq" element={<CircularRfq />} />
        <Route path="/create-circular-rfq" element={<CreateCircularRfq />} />
        <Route path="/edit-circular-rfq/:id" element={<EditCircularRfq />} />
        <Route path="/show-circular-rfq/:id" element={<ShowCircularRfq />} />

        <Route
          path="/vendor-quotation-list/:id"
          element={<VendorQuotationList />}
        />

        <Route
          path="/show-vendor-quotation/:id"
          element={<ShowVendorQuotation />}
        />

        <Route path="/circular-compare/:id" element={<CircularCompare />} />

        <Route
          path="/vendor-quotation-sent/:id"
          element={<VendorQuotationSent />}
        />
        <Route path="/final-quotation" element={<FinalQuotation />} />
        <Route
          path="/final-quotation-view/:id"
          element={<FinalQuotationView />}
        />

        <Route path="/purchase-order" element={<PurchaseOrder />} />
        <Route
          path="/purchase-order-view/:id"
          element={<PurchaseOrderView />}
        />

        {/* Others Receive */}
        <Route path="/others-receive" element={<OthersReceive />} />
        <Route
          path="/create-others-receive"
          element={<CreateothersReceive />}
        />
        <Route
          path="/edit-others-receive/:id"
          element={<EditothersReceive />}
        />
        <Route path="/others-receive/:id" element={<OthersReceiveView />} />

        {/* Grn */}
        <Route path="/create-grn/:id/:type" element={<CreateGrn />} />
        <Route path="/grn" element={<Grn />} />
        <Route path="/grn/:id" element={<GrnView />} />
        <Route path="/edit/grn/:id" element={<GrnEdit />} />

        {/* Purchase order Return */}
        <Route
          path="/purchase-order-return"
          element={<PurchaseOrderReturn />}
        />
        <Route
          path="/create-purchase-order-return"
          element={<PurchaseOrderReturnCreate />}
        />
        <Route
          path="/edit/purchase-order-return/:id"
          element={<PurchaseOrderReturnEdit />}
        />
        <Route
          path="/purchase-order-return-view/:id"
          element={<PurchaseOrderReturnView />}
        />

        {/* Assets */}
        <Route path="/asset-category" element={<AssetCategory />} />
        <Route
          path="/create-asset-category"
          element={<CreateAssetCategory />}
        />
        <Route
          path="/edit-asset-category/:id"
          element={<EditAssetCategory />}
        />

        <Route path="/asset" element={<Asset />} />
        <Route path="/create-asset" element={<CreateAsset />} />
        <Route path="/edit-asset/:id" element={<EditAsset />} />

        <Route
          path="/asset-direct-purchase"
          element={<AssetDirectPurchase />}
        />
        <Route
          path="/create-asset-direct-purchase"
          element={<CreateAssetDirectPurchase />}
        />
        <Route
          path="/edit-asset-direct-purchase/:id"
          element={<EditAssetDirectPurchase />}
        />

        <Route path="/asset-assign" element={<AssetAssign />} />
        <Route path="/create-asset-assign" element={<CreateAssetAssign />} />
        <Route path="/edit-asset-assign/:id" element={<EditAssetAssign />} />
        <Route
          path="/return-asset-assign/:id"
          element={<ReturnAssetAssign />}
        />
        <Route path="/damage-asset-claim/:id" element={<DamageAssetClaim />} />

        <Route
          path="/asset-maintenence-claim"
          element={<AssetMaintenenceClaim />}
        />
        <Route
          path="/create-asset-maintenence-claim"
          element={<CreateAssetMaintenenceClaim />}
        />
        <Route
          path="/edit-asset-maintenence-claim/:id"
          element={<EditAssetMaintenenceClaim />}
        />

        <Route path="/asset-damage-claim" element={<AssetDamageClaim />} />
        <Route
          path="/create-asset-damage-claim"
          element={<CreateAssetDamageClaim />}
        />
        <Route
          path="/edit-asset-damage-claim/:id"
          element={<EditAssetDamageClaim />}
        />

        <Route path="/asset-circular-rfq" element={<AssetCircularRfq />} />
        <Route
          path="/asset-vendor-quotation-list/:id"
          element={<VendorAssetQuotationList />}
        />
        <Route
          path="/create-asset-circular-rfq"
          element={<CreateAssetCircularRfq />}
        />
        <Route
          path="/edit-asset-circular-rfq/:id"
          element={<EditAssetCircularRfq />}
        />
        <Route
          path="/vendor-asset-quotation-sent/:id"
          element={<VendorAssetQuotationSent />}
        />

        <Route
          path="/asset-circular-compare/:id"
          element={<AssetCircularCompare />}
        />

        <Route
          path="/asset-final-quotation"
          element={<AssetFinalQuotation />}
        />
        <Route
          path="/asset-final-quotation-view/:id"
          element={<AssetFinalQuotationView />}
        />

        <Route path="/asset-purchase-order" element={<AssetPurchaseOrder />} />
        <Route
          path="/asset-purchase-order-view/:id"
          element={<AssetPurchaseOrderView />}
        />

        <Route path="/asset-create-grn/:id" element={<AssetCreateGrn />} />
        <Route path="/asset-grn" element={<AssetGrn />} />
        <Route path="/asset-grn/:id" element={<AssetGrnView />} />
        <Route path="/asset-edit/grn/:id" element={<AssetGrnEdit />} />

        <Route
          path="/asset-purchase-order-return"
          element={<AssetPurchaseOrderReturn />}
        />
        <Route
          path="/create-asset-purchase-order-return"
          element={<AssetPurchaseOrderReturnCreate />}
        />
        <Route
          path="/edit/asset-purchase-order-return/:id"
          element={<AssetPurchaseOrderReturnEdit />}
        />
        <Route
          path="/asset-purchase-order-return-view/:id"
          element={<AssetPurchaseOrderReturnView />}
        />

        {/* Production */}
        <Route path="/receipe" element={<Receipe />} />
        <Route path="/create-receipe" element={<CreateReceipe />} />
        <Route path="/edit-receipe/:id" element={<EditReceipe />} />

        <Route path="/receipe-factory" element={<ReceipeFactory />} />
        <Route
          path="/create-receipe-factory"
          element={<CreateReceipeFactory />}
        />
        <Route
          path="/edit-receipe-factory/:id"
          element={<EditReceipeFactory />}
        />

        <Route path="/machineries" element={<Machineries />} />
        <Route path="/create-machineries" element={<CreateMachineries />} />
        <Route path="/edit-machineries/:id" element={<EditMachineries />} />

        <Route path="/factory-machineries" element={<FactoryMachineries />} />
        <Route
          path="/create-factory-machineries"
          element={<CreateFactoryMachineries />}
        />
        <Route
          path="/edit-factory-machineries/:id"
          element={<EditFactoryMachineries />}
        />

        <Route
          path="/requisition-production"
          element={<RequisitionProduction />}
        />
        <Route
          path="/create-requisition-production"
          element={<CreateRequisitionProduction />}
        />
        <Route
          path="/edit-requisition-production/:id"
          element={<EditRequisitionProduction />}
        />
        <Route
          path="/requisition-production-view/:id"
          element={<ViewRequisitionProduction />}
        />

        <Route path="/issue" element={<Issue />} />
        <Route path="/issue-view/:id" element={<ViewIssue />} />

        <Route path="/create-issue/:id" element={<CreateIssue />} />
        <Route
          path="/edit-issue/:requisition_production_id/:id"
          element={<EditIssue />}
        />
        <Route path="/rmpm-receive/:id" element={<RmPmReceive />} />
        <Route
          path="/production-generate/:id"
          element={<ProductionGenerate />}
        />
        <Route path="/production-receive/:id" element={<ProductionReceive />} />

        <Route path="/production" element={<Production />} />

        <Route path="/depo-transfer" element={<DepoTransfer />} />

        <Route path="/show-transfer/:id" element={<FactoryTransferShow />} />
        <Route path="/factory-transfer" element={<FactoryTransfer />} />
        <Route
          path="/create-factory-transfer"
          element={<CreateFactoryTransfer />}
        />
        <Route
          path="/edit-factory-transfer/:id"
          element={<EditFactoryTransfer />}
        />

        <Route path="/damage-return" element={<DamageReturn />} />
        <Route path="/create-damage-return" element={<CreateDamageReturn />} />
        <Route path="/edit-damage-return/:id" element={<EditDamageReturn />} />

        {/* Assets */}
        <Route path="/asset-category" element={<AssetCategory />} />
        <Route
          path="/create-asset-category"
          element={<CreateAssetCategory />}
        />
        <Route
          path="/edit-asset-category/:id"
          element={<EditAssetCategory />}
        />

        <Route path="/asset" element={<Asset />} />
        <Route path="/create-asset" element={<CreateAsset />} />
        <Route path="/edit-asset/:id" element={<EditAsset />} />

        <Route path="/asset-circular-rfq" element={<AssetCircularRfq />} />
        <Route
          path="/create-asset-circular-rfq"
          element={<CreateAssetCircularRfq />}
        />
        <Route
          path="/edit-asset-circular-rfq/:id"
          element={<EditAssetCircularRfq />}
        />

        {/* Sales */}
        <Route path="/primary-sales" element={<PrimarySales />} />
        <Route path="/create-primary-sales" element={<CreatePrimarySales />} />
        <Route path="/edit-primary-sales/:id" element={<EditPrimarySales />} />
        <Route path="/payment-receive/:id" element={<PaymentReceive />} />
        <Route
          path="/payment-receive-coa/:id"
          element={<PaymentReceiveCoa />}
        />
        <Route path="/primary-sales-detail/:id" element={<OrderDetail />} />
        <Route path="/delivery-order/:id" element={<DeliveryOrder />} />

        <Route path="/mushok-6-3/:id" element={<Mushok63 />} />
        <Route path="/chalan/:id" element={<Chalan />} />

        <Route path="/distributors" element={<Distributors />} />
        <Route path="/create-distributors" element={<CreateDistributors />} />
        <Route path="/edit-distributors/:id" element={<EditDistributors />} />
        <Route path="/distributor-details/:id" element={<DistributorsView />} />

        {/* Secondary Sales */}
        <Route path="/monthly-target" element={<MonthlyTarget />} />
        <Route path="/monthly-target-view" element={<MonthlyTargetView />} />
        <Route path="/daily-report" element={<MonthlyTargetDailyReport />} />
        <Route path="/summary-report" element={<SummaryReport />} />
        <Route
          path="/monthly-target-view-region-wise/:targetId/:id"
          element={<MonthlyTargetViewRegionWise />}
        />
        <Route
          path="/monthly-target-view-base-wise-daily/:targetId/:regionId/:baseId"
          element={<MonthlyTargetViewBaseWiseDaily />}
        />
        <Route path="/create-target" element={<CreateMonthlyTarget />} />
        <Route path="/edit-target" element={<EditMonthlyTarget />} />
        <Route
          path="/edit-monthly-target/:id"
          element={<EditMonthlyTarget />}
        />

        <Route path="/opening-stock" element={<OpeningStock />} />
        <Route path="/opening-stock-view" element={<OpeningStockView />} />
        <Route path="/create-opening-stock" element={<CreateOpeningStock />} />
        <Route path="/edit-opening-stock" element={<EditOpeningStock />} />
        <Route path="/edit-opening-stock/:id" element={<EditOpeningStock />} />

        {/* Reports */}
        <Route path="/reports" element={<Reports />} />
        <Route path="/hr-salary-structure" element={<HrSalaryStructure />} />
        <Route path="/hr-leave-report" element={<HrLeaveReport />} />
        <Route
          path="/monthly-salary-report"
          element={<MonthlySalaryReport />}
        />
        <Route path="/attendance-report" element={<AttendanceReport />} />
        <Route
          path="/salary-payment-report"
          element={<SalaryPaymentReport />}
        />
        <Route path="/apa-report" element={<ApaReport />} />
        <Route
          path="/chart-of-account-ledger-report"
          element={<ChartOfAccountLedgerReport />}
        />
        <Route path="/sub-category-list" element={<SubcategoryListReport />} />
        <Route
          path="/subcategory-ledger-report/:id"
          element={<SubcategoryLedgerReport />}
        />
        <Route
          path="/vendor-supplier-payable-report"
          element={<VendorSupplierPayableReport />}
        />
        <Route
          path="/distributor-payable-report"
          element={<DistributorPayableReport />}
        />
        <Route
          path="/receipt-payment-summary-report"
          element={<ReceiptPaymentSummaryReport />}
        />
        <Route
          path="/vendor-supplier-receivable-report"
          element={<VendorSupplierReceivableReport />}
        />
        <Route
          path="/distributor-receivable-report"
          element={<DistributorReceivableReport />}
        />
        <Route path="/trial-balance" element={<TrialBalance />} />

        {/* Depot Report */}
        <Route
          path="/report/depo-wise-product-stock"
          element={<DepoWiseProductStock />}
        />
        <Route
          path="/report/depo-wise-raw-material-stock"
          element={<RawMaterialStock />}
        />
        <Route
          path="/report/depo-wise-packing-material-stock"
          element={<PackingMaterialStock />}
        />
        <Route path="/report/product-stock" element={<ProductStock />} />
        <Route path="/report/distributor" element={<Distributor />} />
      </Routes>
    </div>
  </BrowserRouter>
  // </React.StrictMode>
);
reportWebVitals();
